import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import "../assets/styles/index.scss"

import Scroll from "../components/global/scroll"
import TopNavigation from "../components/global/top_navigation"
import MainLayout from "../layouts/main_layout"

import About from "../components/about"
import BuildSomething from "../components/build-something"
import List from "../components/case-studies/list"
import Clients from "../components/clients/"
import Footer from "../components/footer/"
import HomepageBanner from "../components/homepage_banner"
import People from "../components/people/"

const Application = ({}) => {
  return (
    <MainLayout>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Hardhat is an independent creative agency helping brands capitalise on the why, when, where, what and how of human behaviour."
        />

        <link
          rel="apple-touch-icon-precomposed"
          sizes="57x57"
          href="/images/favicon/apple-touch-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="114x114"
          href="/images/favicon/apple-touch-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="72x72"
          href="/images/favicon/apple-touch-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="144x144"
          href="/images/favicon/apple-touch-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="60x60"
          href="/images/favicon/apple-touch-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="120x120"
          href="/images/favicon/apple-touch-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="76x76"
          href="/images/favicon/apple-touch-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="152x152"
          href="/images/favicon/apple-touch-icon-152x152.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/favicon/favicon-196x196.png"
          sizes="196x196"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/favicon/favicon-96x96.png"
          sizes="96x96"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/favicon/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/favicon/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="icon"
          type="image/png"
          href="/images/favicon/favicon-128.png"
          sizes="128x128"
        />
        <title>Hardhat | Brands. Built on behaviour</title>
        <link rel="canonical" href="https://hardhat.com.au/misc" />
      </Helmet>
      <div className="wrapper">
        <div className="container">
          <p style={{ margin: "5vh 0" }} className="mega1">
            Mega 1: Heading for type styles
          </p>
          <p style={{ marginBottom: "5vh" }} className="display1">
            Display 1: Heading for type styles
          </p>
          <p style={{ marginBottom: "5vh" }} className="display2">
            Display 2: Heading for type styles
          </p>
          <p style={{ marginBottom: "5vh" }} className="display3">
            Display 3: Heading for type styles
          </p>
          <p style={{ marginBottom: "5vh" }} className="display4">
            Display 4: Heading for type styles
          </p>
          <p style={{ marginBottom: "5vh" }} className="display5">
            Display 5: Heading for type styles
          </p>
          <p style={{ marginBottom: "5vh" }} className="body1">
            Body 1: Heading for type styles
          </p>
          <p style={{ marginBottom: "5vh" }} className="body2">
            Body 2: Heading for type styles
          </p>
          <p style={{ marginBottom: "5vh" }} className="body3">
            Body 3: Heading for type styles
          </p>
          <p style={{ marginBottom: "5vh" }} className="label1">
            Label 1: Heading for type styles
          </p>
          <p style={{ marginBottom: "5vh" }} className="body3">
            Label 2: Heading for type styles
          </p>
          <p style={{ marginBottom: "5vh" }} className="script">
            Script: Heading for type styles
          </p>
        </div>
      </div>
    </MainLayout>
  )
}
export default Application
